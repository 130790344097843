import { Global as GlobalEmotion } from '@emotion/react'

const Global = () => (
  <GlobalEmotion
    styles={theme => ({
      '#__next': {
        display: 'flex'
      }
    })}
  />
)

export default Global;
