export const odeonTheme = {
  colours: {
    text: '#fff',
    background: '#000',
  },
  fontFamily: 'gtwalsheim, system-ui, sans-serif',
}

export default {
  useCustomProperties: false,
  fontSizes: ['1.615vw', '2.344vw', '4.688vw', '4vw', '9.376vw', '3.5vw' /* '90px' */],
  lineHeights: ['1.615vw', /* '31px' */ '4.375vw' /* '84px' */],
  fontWeights: [500, 700],
  letterSpacing: ['0', '-0.015em'],
  text: {
    menuTitle: {
      color: odeonTheme.colours.text,
      fontWeight: 1,
      lineHeight: 1,
      letterSpacing: 1
    },
    menuItem: {
      color: odeonTheme.colours.text,
      fontWeight: 0,
      whiteSpace: 'pre-wrap'
    },
    noItems: {
      color: odeonTheme.colours.text,
      fontSize: 2,
    }
  },
  styles: {
    root: {
      WebkitFontSmoothing: 'subpixel-antialiased',
      MozOsxFontSmoothing: 'grayscale',
      fontFamily: odeonTheme.fontFamily,
      'body, document': {
        bg: odeonTheme.colours.background,
        width: '100vw',
        height: '100vh',
        overflow: 'hidden',
      },
      a: {
        color: odeonTheme.colours.text,
        fontSize: 1,
      },
      h1: {
        fontSize: 2,
        color: odeonTheme.colours.text,
        mt: 0,
      },
      h2: {
        fontSize: 2,
        color: odeonTheme.colours.text,
        mt: '1em',
      },
      h3: {
        fontSize: 2,
        color: odeonTheme.colours.text,
        mt: 0,
      }
    }
  }
}
